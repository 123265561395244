import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import featureToggles from '../../../utils/featureToggles';
import SoldOutPage from '../soldOutPage';
import ImageCarousel from '../../registerImageCarousel';

const RegisterPage = () => {

  const { t } = useTranslation();

  if (featureToggles.soldOut()) {
    return <SoldOutPage title={t('Pages.SoldOut.Title')} header={t('Pages.SoldOut.Header')} />;
  }

  useEffect(() => {
    document.title = t('Pages.Register.Title');
  }, []);

  return (
    <div className="con-container parent-grid">
      <div className="con-content left-box">
        <div className="con-flex__container headertext">
          <h1 className="con-heading con-heading__l--margin-bottom-3">{t('Pages.Register.Header')}</h1>
        </div>
        <p className="con-text__xs--margin-bottom-2">
          {t('Pages.Register.TicketsForAiCon')} <a className="con-link__nounderline con-wrap"
            href="https://aisling-events.com/product/ai-con-2024/" target="_blank"
            rel="noreferrer noopener">{t('Pages.Register.OnSaleHere')}</a>. 
        </p>
        <p className="con-text__xs--margin-bottom-2">{t('Pages.Register.EarlyBird')}</p>
        <p className="con-text__xs--margin-bottom-2">
          {t('Pages.Register.TicketOptions')}
        </p>
        <p className="earlybird con-text__xs--margin-bottom-1--bold">{t('Pages.Register.Tickets.EarlySingleTicket')}</p>
        <p className="con-text__xs--margin-bottom-2">{t('Pages.Register.Tickets.EarlySingleTicketDescription')}</p>

        <p className="earlybird con-text__xs--margin-bottom-1--bold">{t('Pages.Register.Tickets.EarlyBirdCompanyPass')}</p>
        <p className="con-text__xs--margin-bottom-2">{t('Pages.Register.Tickets.EarlyBirdCompanyPassDescription')}</p>

        <p className="con-text__xs--margin-bottom-1--bold">{t('Pages.Register.Tickets.SingleTicket')}</p>
        <p className="con-text__xs--margin-bottom-2">{t('Pages.Register.Tickets.SingleTicketDescription')}</p>

        <p className="con-text__xs--margin-bottom-1--bold">{t('Pages.Register.Tickets.CompanyPass')}:</p>
        <p className="con-text__xs--margin-bottom-2">{t('Pages.Register.Tickets.CompanyPassDescription')} </p>
        
        <div className="con-button__wrap">
        <a className="con-register__button" href="https://aisling-events.com/product/ai-con-2024/">{t('Common.Tickets')}</a>
        </div>
        
      </div>
      <div className="con-content right-box">
        <ImageCarousel />
      </div>
    </div>
  );
};

export default RegisterPage;
