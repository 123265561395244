const featureToggles = {
  // todo: implement the right feature toggle here
  soldOut: () => true,
  curators: () => false,
  schedule: () => true,
  secondSchedule: () => true,
  archive: () => true,
  partners: () => true,
  contactFormAuth: () => true,
  liveStream: () => false,
  pressReleases: () => true,
};
export default featureToggles;
